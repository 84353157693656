import {
  ALL_FORMS_OF_INSURANCE,
  FORMS_BY_INSURANCE_TYPE,
  FORMS_BY_INSURANCE_TYPE_LOADER,
  GET_INSURANCE_TYPES_SUCCESS,
  SINGLE_FORM_BY_INSURANCE_TYPE,
  SINGLE_FORM_BY_INSURANCE_TYPE_LOADER,
  ALL_FORMS,
  SINGLE_FORM_BY_INSURANCE_ERROR,
} from '../contants';

const initState = {
  // loaders of insrancetype
  formsByInsuranceType: [],
  formsByInsuranceTypeLoader: true,
  // loader of forms
  singleFormInsurance: null,
  singleFormLoader: false,
  // all forms
  insurancesAllForms: [],
  // all Admin Forms
  allForms: [],
};

const formReducer = (state = initState, action) => {
  switch (action.type) {
    case SINGLE_FORM_BY_INSURANCE_TYPE:
      return {
        ...state,
        singleFormInsurance: action?.payload,
        singleFormLoader: false,
      };

    case SINGLE_FORM_BY_INSURANCE_TYPE_LOADER:
      return {
        ...state,
        singleFormLoader: action?.payload,
      };
    case SINGLE_FORM_BY_INSURANCE_ERROR:
      return {
        ...state,
        singleFormError: action?.payload,
      };
    case ALL_FORMS:
      return {
        ...state,
        allForms: action?.payload,
      };
    case ALL_FORMS_OF_INSURANCE:
      return {
        ...state,
        insurancesAllForms: action?.payload,
      };

    case FORMS_BY_INSURANCE_TYPE:
      return {
        ...state,
        formsByInsuranceType: action.payload,
      };
    case FORMS_BY_INSURANCE_TYPE_LOADER:
      return {
        ...state,
        formsByInsuranceTypeLoader: action.payload,
      };

    default:
      return { ...state };
  }
};

export default formReducer;
