import React, { useEffect } from 'react';
import { customAxios, removeAuthHeader } from 'lib/custom-axios';
import { useHistory } from 'react-router-dom';

const Interceptors = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    customAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const message = error?.response?.data?.message;
        if (
          message === 'jwt expired' ||
          message === 'User is deactivated by admin' ||
          message === 'jwt malformed'
        ) {
          localStorage.clear();
          removeAuthHeader();
          history.push('/auth/login');
        }
        throw error;
      }
    );
  }, []);
  return children;
};

export default Interceptors;
