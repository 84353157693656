import {
  FORMS_BY_INSURANCE_TYPE,
  FORMS_BY_INSURANCE_TYPE_LOADER,
  GET_INSURANCE_TYPES_SUCCESS,
  SINGLE_FORM_BY_INSURANCE_TYPE,
  SINGLE_FORM_BY_INSURANCE_TYPE_LOADER,
} from '../contants';

const initState = {
  insuranceTypes: [],
  singleInsurance: null,
};

const insuranceReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_INSURANCE_TYPES_SUCCESS:
      return {
        ...state,
        insuranceTypes: [...action?.payload],
      };

    default:
      return { ...state };
  }
};

export default insuranceReducer;
