import { setAuthHeader, removeAuthHeader } from '../../lib/custom-axios';
import {
  UPDATE_STATS,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_CUSTOMER,
  REGISTER_CUSTOMER_SUCCESS,
  REGISTER_CUSTOMER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REVERIFY,
  REGISTERING_USER,
  USER_REGISTERED,
  USER_REGISTRATION_ERROR,
  SET_USER_REGISTERED_CLEAN,
  UPDATE_CURRENT_USER,
} from '../contants';

const INIT_STATE = {
  currentUser: null,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATS:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    case UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    }
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case REVERIFY: {
      const token = localStorage.getItem('mmc_token');
      const user = JSON.parse(localStorage.getItem('mmc_user') || 'false');
      if (user && token) {
        setAuthHeader(token);
        return {
          ...state,
          currentUser: user,
        };
      }
      localStorage.clear();
      return state;
    }
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload.message,
      };
    case REGISTER_CUSTOMER:
      return { ...state, loading: true, error: '' };
    case REGISTER_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case REGISTER_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      localStorage.clear();
      removeAuthHeader();
      return { ...state, currentUser: null, error: '' };
    case REGISTERING_USER:
      return { ...state, registeringUser: true };
    case USER_REGISTERED:
      return { ...state, registeringUser: false, userRegistered: true };
    case USER_REGISTRATION_ERROR:
      return {
        ...state,
        registeringUser: false,
        userRegistered: false,
        userRegistrationError: action?.payload?.message,
      };
    case SET_USER_REGISTERED_CLEAN: {
      return {
        ...state,
        userRegistered: false,
        registeringUser: false,
        userRegistrationError: false,
      };
    }
    default:
      return { ...state };
  }
};
