import { customAxios, setAuthHeader } from '../../lib/custom-axios';
/* eslint-disable import/no-unresolved */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_CUSTOMER,
  REGISTER_CUSTOMER_SUCCESS,
  REGISTER_CUSTOMER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REVERIFY,
  USER_REGISTERED,
  REGISTERING_USER,
  USER_REGISTRATION_ERROR,
  UPDATE_STATS,
  UPDATE_CURRENT_USER,
} from '../contants';
import { NotificationManager } from '../../components/common/react-notifications';

export const reverify = () => ({ type: REVERIFY });

export const updateCurrentUserState = (user) => ({
  type: UPDATE_CURRENT_USER,
  payload: user,
});

export const loginUser = (data, history) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: null,
  });
  try {
    const res = await customAxios.post('/auth/login', data);
    localStorage.setItem('mmc_token', res?.data?.token);
    localStorage.setItem('mmc_user', JSON.stringify(res?.data?.user));
    setAuthHeader(res.data?.token);
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    const error = err?.response?.data?.message;
    NotificationManager.warning(error, '', 3000, null, null, '');
    dispatch({
      type: LOGIN_USER_ERROR,
      payload: error,
    });
  }
};

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (data, history) => async (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD,
    payload: data,
  });
  try {
    const res = await customAxios.post('/auth/forgetpassword', data);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: null,
    });
    NotificationManager.success(res?.data?.message, '', 3000, null, null, '');
    history.push("/auth/login");
  } catch (err) {
    const error = err?.response?.data?.message;
    NotificationManager.warning(error, '', 3000, null, null, '');
    dispatch({
      type: FORGOT_PASSWORD_ERROR,
      payload: error,
    });
  }
};

export const resetPassword = (data, history) => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD,
    payload: data,
  });
  try {
    const res = await customAxios.post('/auth/resetPassword', data);
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: null,
    });
    NotificationManager.success(res?.data?.message, '', 3000, null, null, '');
    history.push("/auth/login");
  } catch (err) {
    const error = err?.response?.data?.message;
    NotificationManager.warning(error, '', 3000, null, null, '');
    dispatch({
      type: RESET_PASSWORD_ERROR,
      payload: error,
    });
  }
};

export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerCustomer = (user, history) => ({
  type: REGISTER_CUSTOMER,
  payload: { user, history },
});
export const registerCustomerSuccess = (user) => ({
  type: REGISTER_CUSTOMER_SUCCESS,
  payload: user,
});
export const registerCustomerError = (message) => ({
  type: REGISTER_CUSTOMER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const updateStats = (gavelloTokens) => ({
  type: UPDATE_STATS,
  payload: gavelloTokens,
});

export const createUser = (data) => async (dispatch) => {
  dispatch({ type: REGISTERING_USER });
  try {
    await customAxios.post('/auth/create-user', data);
    dispatch({ type: USER_REGISTERED });
  } catch (err) {
    dispatch({
      type: USER_REGISTRATION_ERROR,
      payload: { message: err?.response?.data?.message || err?.message },
    });
  }
};
