/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const UPDATE_STATS = 'UPDATE_STATS';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_ERROR = 'REGISTER_CUSTOMER_ERROR';
export const REGISTER_BROKER = 'REGISTER_BROKER';
export const REGISTER_BROKER_SUCCESS = 'REGISTER_BROKER_SUCCESS';
export const REGISTER_BROKER_ERROR = 'REGISTER_BROKER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const REVERIFY = 'REVERIFY';
export const REGISTERING_USER = 'REGISTERING_USER';
export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_REGISTRATION_ERROR = 'USER_REGISTRATION_ERROR';
export const SET_USER_REGISTERED_CLEAN = 'SET_USER_REGISTERED_CLEAN';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* INSURANCE TYPES  */
export const GET_INSURANCE_TYPES = 'GET_INSURANCE_TYPES';
export const GET_INSURANCE_TYPES_ERROR = 'GET_INSURANCE_TYPE_ERROR';
export const ADD_INSURANCE_TYPE_ERROR = 'ADD_INSURANCE_TYPE_ERROR';
export const UPDATE_INSURANCE_TYPE_ERROR = 'UPDATE_INSURANCE_TYPE_ERROR';
export const DELETE_INSURANCE_TYPE_ERROR = 'DELETE_INSURANCE_TYPE_ERROR';
export const ACTIVATE_INSURANCE_TYPE_ERROR = 'ACTIVATE_INSURANCE_TYPE_ERROR';

export const GET_INSURANCE_TYPES_SUCCESS = 'GET_INSURANCE_TYPE_SUCCESS';
export const GET_INSURANCE_TYPES_ADMIN_SUCCESS =
  'GET_INSURANCE_TYPES_ADMIN_SUCCESS';
export const ADD_INSURANCE_TYPE_SUCCESS = 'ADD_INSURANCE_TYPE_SUCCESS';
export const UPDATE_INSURANCE_TYPE_SUCCESS = 'UPDATE_INSURANCE_TYPE_SUCCESS';
export const DELETE_INSURANCE_TYPE_SUCCESS = 'DELETE_INSURANCE_TYPE_SUCCESS';
export const ACTIVATE_INSURANCE_TYPE_SUCCESS =
  'ACTIVATE_INSURANCE_TYPE_SUCCESS';
export const FORMS_BY_INSURANCE_TYPE = 'FORMS_BY_INSURANCE_TYPE';
export const FORMS_BY_INSURANCE_TYPE_LOADER = 'FORMS_BY_INSURANCE_TYPE_LOADER';

export const SINGLE_FORM_BY_INSURANCE_TYPE_LOADER =
  'SINGLE_FORM_BY_INSURANCE_TYPE_LOADER';
export const SINGLE_FORM_BY_INSURANCE_TYPE = 'SINGLE_FORM_BY_INSURANCE_TYPE';
export const SINGLE_FORM_BY_INSURANCE_ERROR =
  'SINGLE_FORM_BY_INSURANCE_TYPE_ERROR';
export const ALL_FORMS_OF_INSURANCE = 'ALL_FORMS_OF_INSURANCE';
export const ALL_FORMS = 'ALL_FORMS';

// *********************AUCTIONS  TYPES**********************//

export const GET_USER_AUCTIONS = 'GET_USER_AUCTIONS';
