import axios from 'axios';

export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENV,
});

export const setAuthHeader = (token) => {
  customAxios.defaults.headers.common.Authorization = `bearer ${token}`;
};

export const removeAuthHeader = () => {
  customAxios.defaults.headers.common.Authorization = undefined;
};
